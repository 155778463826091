<template>
  <div>
    <Loading
      v-if="deleting"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
    <!-- MOSTRAR FORM -->
    <v-dialog
      v-model="isDialogVisible"
      scrollable
      max-width="450px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ $t('menu.suplementos') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="mt-5">
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="item.name"
                :label="$t('lbl.name')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-select
                v-model="item.model"
                :items="models"
                :label="$t('menu.modelo')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="slug"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.modelo') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>

              <!--<v-row>
                <v-col
                  v-for="(checkType, indCH) in checkTypes"
                  :key="indCH"
                  cols="6"
                  class="my-0 py-0"
                >
                  <v-checkbox
                    v-model="itemModality"
                    :label="checkType.name"
                    :value="checkType.slug"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>-->
              <!--<v-radio-group
                v-model="item.modality"
                row
                hide-details
              >
                <v-radio
                  :label="$t('lbl.byNight')"
                  value="by_night"
                  class="col-4"
                ></v-radio>
                <v-radio
                  :label="$t('lbl.byRoom')"
                  value="by_room"
                  class="col-4"
                ></v-radio>
                <v-radio
                  :label="$t('lbl.byPax')"
                  value="by_pax"
                  class="col-4"
                ></v-radio>
                <v-radio
                  :label="$t('lbl.byRoomNight')"
                  value="by_room_night"
                  class="col-6"
                ></v-radio>
                <v-radio
                  :label="$t('lbl.byPaxNight')"
                  value="by_pax_night"
                  class="col-6"
                ></v-radio>
              </v-radio-group>-->
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisible = !isDialogVisible"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            :loading="loading"
            @click="save"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')} ${item.name}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          {{ $t('msg.deleteItem', { n: item.name }) }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-text>
      <v-row>
        <v-col
          v-if="permisos.includes('nom_suplemento_contrate_hotels:create') || permisos.includes('*')"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :loading="loading"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="text-right"
        >
          {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('lbl.suplementReducc') | lowercase }}
        </v-col>
      </v-row>
    </v-form>

    <v-simple-table v-if="!isLoading">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              {{ $t('lbl.name') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.tipo') }}
            </th>
            <th class="text-uppercase">
              {{ $t('menu.modelo') }}
            </th>
            <!--<th class="text-uppercase">
              {{ $t('lbl.byNight') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.byRoom') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.byPax') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.byRoomNight') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.byPaxNight') }}
            </th>-->
            <th
              v-if="
                permisos.includes('nom_suplemento_contrate_hotels:edit') ||
                  permisos.includes('nom_suplemento_contrate_hotels:destroy') ||
                  permisos.includes('*')
              "
              class="text-uppercase"
              style="width: 10px; text-align: center"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>{{ getName(iten) }}</td>
            <td>
              <span v-if="iten.is_reduction">{{ $t('lbl.reduccion') }}</span>
              <span v-else>{{ $t('lbl.suplemento') }}</span>
            </td>
            <td>
              <span v-if="iten.model === 'alojamiento'">
                {{ $t('lbl.rentToHotels') }}
              </span>
              <span v-else-if="iten.model === 'plan-alimentos'">
                {{ $t('lbl.planFoot') }}
              </span>
              <span v-else-if="iten.model === 'check-in-out'">
                {{ $t('lbl.checkInOut') }}
              </span>
              <span v-else-if="iten.model === 'temporada-pico'">
                {{ $t('lbl.seasonPico') }}
              </span>
              <span v-else-if="iten.model === 'cena'">
                {{ $t('lbl.cena') }}
              </span>
              <span v-else-if="iten.model === 'traslado'">
                {{ $t('lbl.traslado') }}
              </span>
            </td>
            <!--<td>
              <v-icon
                v-if="iten.by_night"
                color="primary"
                class="mr-2"
              >
                mdi-check
              </v-icon>
              <v-icon
                v-else
                color="error"
                class="mr-2"
              >
                mdi-close
              </v-icon>
            </td>
            <td>
              <v-icon
                v-if="iten.by_room"
                color="primary"
                class="mr-2"
              >
                mdi-check
              </v-icon>
              <v-icon
                v-else
                color="error"
                class="mr-2"
              >
                mdi-close
              </v-icon>
            </td>
            <td>
              <v-icon
                v-if="iten.by_pax"
                color="primary"
                class="mr-2"
              >
                mdi-check
              </v-icon>
              <v-icon
                v-else
                color="error"
                class="mr-2"
              >
                mdi-close
              </v-icon>
            </td>
            <td>
              <v-icon
                v-if="iten.by_room_night"
                color="primary"
                class="mr-2"
              >
                mdi-check
              </v-icon>
              <v-icon
                v-else
                color="error"
                class="mr-2"
              >
                mdi-close
              </v-icon>
            </td>
            <td>
              <v-icon
                v-if="iten.by_pax_night"
                color="primary"
                class="mr-2"
              >
                mdi-check
              </v-icon>
              <v-icon
                v-else
                color="error"
                class="mr-2"
              >
                mdi-close
              </v-icon>
            </td>-->
            <td
              v-if="
                permisos.includes('nom_suplemento_contrate_hotels:edit') ||
                  permisos.includes('nom_suplemento_contrate_hotels:destroy') ||
                  permisos.includes('*')
              "
              style="text-align: center"
            >
              <v-menu
                v-if="
                  permisos.includes('nom_suplemento_contrate_hotels:edit') ||
                    permisos.includes('nom_suplemento_contrate_hotels:destroy') ||
                    permisos.includes('*')
                "
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-if="permisos.includes('nom_suplemento_contrate_hotels:edit') || permisos.includes('*')"
                    link
                    @click="editItem(iten)"
                  >
                    <v-list-item-title>
                      <v-btn icon>
                        <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                      </v-btn>
                      <span>{{ $t('btn.edit') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      (permisos.includes('nom_suplemento_contrate_hotels:destroy') || permisos.includes('*')) &&
                        iten.slug !== 'late-check-out' &&
                        iten.slug !== 'early-check-in' &&
                        iten.slug !== 'ap' &&
                        iten.slug !== 'map' &&
                        iten.slug !== 'cp' &&
                        iten.slug !== 'ep' &&
                        iten.slug !== '3er-pax' &&
                        iten.slug !== '4to-pax' &&
                        iten.slug !== '5to-pax' &&
                        iten.slug !== '6to-pax' &&
                        iten.slug !== '7mo-pax' &&
                        iten.slug !== '8vo-pax' &&
                        iten.slug !== 'cena-de-fin-de-ano' &&
                        iten.slug !== 'transfer-maritimo-round-trip' &&
                        iten.slug !== 'vista-marsea-view' &&
                        iten.slug !== 'habitacion-uso-individual' &&
                        iten.slug !== 'suplemento-de-extrema-alta' &&
                        iten.slug !== 'semana-santa' &&
                        iten.slug !== 'parrandas-de-remedios' &&
                        iten.slug !== 'festival-del-caribe' &&
                        iten.slug !== 'cena-de-navidad-y-fin-de-ano' &&
                        iten.slug !== 'cena-de-navidad' &&
                        iten.slug !== 'cena-de-gala'
                    "
                    link
                    @click="deleteItem(iten)"
                  >
                    <v-list-item-title>
                      <v-btn icon>
                        <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-btn>
                      <span>{{ $t('btn.delete') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiDotsVertical,
} from '@mdi/js'

export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: true,
      deleting: false,
      search: '',
      items: [],
      totalItems: 0,
      totalF: 0,
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiDotsVertical,
      },
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      item: {
        modality: [],
      },
      itemModality: [],
      checkTypes: [],
      itemId: null,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
      models: [],
    }
  },
  created() {
    this.models.push({
      name: 'Alojamiento',
      slug: 'alojamiento',
    })
    this.models.push({
      name: 'Plan alimentos',
      slug: 'plan-alimentos',
    })
    this.models.push({
      name: 'Check-In/Out',
      slug: 'check-in-out',
    })
    this.models.push({
      name: 'Temporada pico',
      slug: 'temporada-pico',
    })
    this.models.push({
      name: 'Cenas',
      slug: 'cenas',
    })
    this.models.push({
      name: 'Traslados',
      slug: 'traslados',
    })

    this.checkTypes.push({
      name: this.$t('lbl.byNight'),
      slug: 'by_night',
    })
    this.checkTypes.push({
      name: this.$t('lbl.byRoom'),
      slug: 'by_room',
    })
    this.checkTypes.push({
      name: this.$t('lbl.byPax'),
      slug: 'by_pax',
    })
    this.checkTypes.push({
      name: this.$t('lbl.byRoomNight'),
      slug: 'by_room_night',
    })
    this.checkTypes.push({
      name: this.$t('lbl.byPaxNight'),
      slug: 'by_pax_night',
    })

    this.profile()
    this.load()
    this.loadAll()
  },
  methods: {
    getName(item) {
      if (this.$i18n.locale === 'es') {
        return item.name
      }

      return item[this.$i18n.locale]
    },
    profile() {
      this.user = JSON.parse(localStorage.getItem('profile'))
      if (this.user.nivel === -1) {
        this.is_nivel_propietario = true
      }

      this.isLoading = false
    },
    load() {
      this.axios
        .get(
          `nom_suplemento_contrate/hotel?page=${this.pagination.current}&per_page=${this.itemsPerPage}&search=${
            this.search
          }&perfil_slug=${localStorage.getItem('perfil')}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page

            this.totalF = res.data.meta.total
          }
        })
    },
    loadAll() {
      this.axios
        .get('nom_suplemento_contrate/hotel?per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
    },
    onPageChange() {
      this.load()
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    newItem() {
      localStorage.removeItem('suplementos-hotels-id')
      localStorage.removeItem('suplementos-hotels-show')
      this.$router.push({ name: 'suplementos-hotels-update' })

      /* this.item = {}
      this.itemModality = []
      this.itemId = null
      this.isDialogVisible = true */
    },
    editItem(item) {
      localStorage.setItem('suplementos-hotels-id', item.id)
      localStorage.removeItem('suplementos-hotels-show')
      this.$router.push({ name: 'suplementos-hotels-update' })

      /* this.isDialogVisible = true
      this.item = item
      this.itemModality = this.item.modality
      this.itemId = item.id */
    },
    save() {
      if (this.item.name && this.itemModality.length > 0) {
        this.loading = true
        this.item.modality = this.itemModality
        this.item.for_hotel = true
        this.item.perfil_slug = localStorage.getItem('perfil')
        if (this.itemId === null) {
          this.axios
            .post('nom_suplemento_contrate', this.item, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(
                    this.$t('msg.nom_suplemento_contrate_hotelsExist', { identificador: this.item.modelo }),
                  )
                }
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.reseat())
        } else {
          this.axios
            .put(`nom_suplemento_contrate/${this.itemId}`, this.item, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.reseat())
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.deleting = true
      this.axios
        .delete(`nom_suplemento_contrate/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            } else if (res.data.data.status === 402) {
              this.$toast.error(this.$t('msg.notDeleteItemRelations', { n: res.data.data.entity }))
            }
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
      this.deleting = false
      this.load()
      this.loadAll()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
